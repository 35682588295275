import React, { useRef } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";

const Jumbotron = ({ slides = [], image = "" }) => {
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  if ((!slides || slides.length === 0) && !image) {
    return <div />;
  }
  return (
    <HeroSection>
      <Carousel
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        loop
        draggable
        height="100%"
        sx={{ flex: 1 }}
        withControls={slides?.length > 1}
        withIndicators
      >
        {image ? (
          <Carousel.Slide>
            <SlideImage
              src={image ? image : require("../images/BG_Image.png")}
            />
          </Carousel.Slide>
        ) : (
          []
        )}
        {slides?.map((slide, index) => (
          <Carousel.Slide key={index}>
            {slide.url ? (
              <a href={slide.url} target="_blank" rel="noreferrer">
                <Img
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                  }}
                  fadeIn
                  objectFit="cover"
                  fluid={slide.image.localFile.childImageSharp.fluid}
                />
              </a>
            ) : (
              <Img
                style={{
                  width: "100%",
                  height: "100%",
                  margin: 0,
                }}
                fadeIn
                objectFit="cover"
                fluid={slide.image.localFile.childImageSharp.fluid}
              />
            )}
          </Carousel.Slide>
        ))}
      </Carousel>
    </HeroSection>
  );
};

export default Jumbotron;

const HeroSection = styled.div`
  background: #ddd;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  height: 100%;
  aspect-ratio: 16/9;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 600px) {
    border-radius: unset;
  }
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
`;
